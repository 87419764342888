<template>
    <v-app class="login-page">
        <v-main>
            <v-container fluid fill-height>
                <v-layout align-center justify-center>
                    <v-flex xs12 sm8 md4 style="max-width: 500px">
                        <v-card class="elevation-12">
                            <v-toolbar dark color="primary">
                                <v-toolbar-title>Login form</v-toolbar-title>
                            </v-toolbar>
                            <v-card-text>
                                <v-form
                                        ref="form"
                                        v-model="valid"
                                        lazy-validation
                                >
                                    <v-text-field
                                            prepend-icon="mdi-account"
                                            v-model="login.email"
                                            :rules="emailRules"
                                            label="E-mail"
                                            required
                                            @keyup.enter.native="submitForm"
                                    ></v-text-field>
                                    <v-text-field
                                            id="password"
                                            @keyup.enter.native="submitForm"
                                            prepend-icon="mdi-lock"
                                            label="Password"
                                            type="password"
                                            :rules="passwordRules"
                                            v-model="login.password">
                                    ></v-text-field>
                                </v-form>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="primary" @click="submitForm">Login</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
    export default {
        name: 'Login',
        data: () => ({
            valid: true,
            login: {
                email: "",
                password: "",
            },
            passwordRules: [
                v => !!v || 'Password is required',
                v => v.length > 2 || 'Name must be more than 2 characters',
            ],
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+/.test(v) || 'E-mail must be valid',
            ],
        }),
        mounted() {
            if (localStorage.getItem('jwt')) {
                this.$router.push({path: '/'})
            }
        },
        methods: {
            submitForm() {
                if (this.$refs.form.validate()) {
                    this.$axios.post(this.$store.state.appUrl + '/auth/local', {
                        identifier: this.login.email.toLowerCase(),
                        password: this.login.password
                    }).then((res) => {
                        if (res.data && res.data.jwt && res.data.user) {
                            localStorage.setItem("jwt", res.data.jwt);
                            this.$store.commit("setState", {user: res.data.user});
                            this.$axios.defaults.headers["Authorization"] = "Bearer " + res.data.jwt;
                            this.$router.push(localStorage.getItem('loginRedirect') || "/");
                            localStorage.removeItem('loginRedirect');
                        } else {
                            this.$router.push("/login")
                        }
                    }).catch(err => {
                        this.$root.notify({color: 'error', text: err.message ? err.message : "Unknown Error!"})
                    });
                } else {
                    this.$root.notify({color: 'error', text: 'Fill all fields'})
                }

            }
        }

    }
</script>
